import React, { createContext, useContext, useEffect, useState } from "react"

interface ConfigContextProps {
  NEXT_PUBLIC_AUTH0_DOMAIN?: string
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY?: string
}

const ConfigContext = createContext<ConfigContextProps | undefined>(undefined)

interface ConfigProviderProps {
  children: React.ReactNode
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<ConfigContextProps>({})

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/api/config")
        const data = await response.json()
        setConfig(data)
      } catch (error) {
        console.error("Failed to fetch config:", error)
      }
    }

    fetchConfig()
  }, [])

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

export const useConfig = (): ConfigContextProps => {
  const context = useContext(ConfigContext)
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider")
  }
  return context
}
