"use client"

import React from "react"

import { ConfigProvider } from "./config-component"

interface ClientConfigProviderProps {
  children: React.ReactNode
}

const ClientConfigProvider: React.FC<ClientConfigProviderProps> = ({
  children,
}) => {
  return <ConfigProvider>{children}</ConfigProvider>
}

export default ClientConfigProvider
